/**
 * Created by blue on 29.11.15.
 */


/*
 * jApp
 */

jApp = {
    init: function () {
        this.onload();
    },
    onload: function () {
        if (typeof onloadfcs !== 'undefined') {
            onloadfcs.forEach(function (fc) {
                fc();
            });
        }
        onloadfcs = [];
        $('.btn-back').bind('click', function () {
            var backUrl = $(this).data('back-url');
            if (backUrl) {
                return document.location.href = backUrl;
            }
            window.history.go(-1);
        });

        //
        // init datepickers
        //
        $('body').on('click', '.datepicker', function () {
            jApp.showDatePicker(this, $(this).data('default-date'));
        });
        $('body').on('click', '.datepicker-icon', function () {
            var input = $($(this).data('alt-field'));
            jApp.showDatePicker(input, $(this).data('default-date'));
        });
    },
    jget: function (url, callback) {
        this.jreq(url, null, callback, 'get');
    },
    jput: function (url, params, callback) {
        this.jreq(url, params, callback, 'put');
    },
    jdelete: function (url, params, callback) {
        this.jreq(url, params, callback, 'delete');
    },
    jpost: function (url, params, callback) {
        this.jreq(url, params, callback, 'post');
    },
    jreq: function (url, params, callback, type) {
        var d = new Date;
        $.ajax({
            url: url,
            dataType: 'json',
            data: params,
            type: type ? type : 'get',
            success: function (json) {
                $.each(json, function (n, d) {
                    switch (n) {
                        case 'html':
                            $.each(d, function (t, h) {
                                $('#' + t).html(h);
                            });
                            break;
                        case 'input':
                            $.each(d, function (t, h) {
                                $('#' + t).val(h);
                            });
                            break;
                        case 'select':
                            $.each(d, function (t, h) {
                                var element = $('#' + t);
                                element.html("");

                                var selected = element.attr("data-selected");
                                $.each(h, function (id, value) {
                                    var html = "<option value='" + id + "'";

                                    if (selected === id) {
                                        html += " selected";
                                    }

                                    html += ">" + value + "</option>";
                                    element.append(html);
                                });

                                if (selected) {
                                    element.removeAttribute("data-selected");
                                }
                            });
                            break;
                        case 'show':
                            $.each(d, function (t, h) {
                                $('#' + t).show();
                            });
                            break;
                        case 'hide':
                            $.each(d, function (t, h) {
                                $('#' + t).hide();
                            });
                            break;
                        case 'focus':
                            $('#' + d).focus();
                            break;
                        case 'text':
                            $.each(d, function (t, h) {
                                $('#' + t).text(h);
                            });
                            break;
                        case 'class-add':
                            $.each(d, function (t, h) {
                                $('#' + t).addClass(h);
                            });
                            break;
                        case 'class-remove':
                            $.each(d, function (t, h) {
                                $('#' + t).removeClass(h);
                            });
                            break;
                        case 'class-toggle':
                            $.each(d, function (t, h) {
                                $('#' + t).toggleClass(h);
                            });
                            break;
                        case 'reload':
                            if (d == 'page') {
                                document.location.reload();
                                return;
                            } else {
                                document.location.href = d;
                            }
                            break;
                        case 'error':
                        case 'message':
                            alert(d);
                            break;
                        case 'callback':
                            var callback = new Function(d.func);
                            if (typeof callback === "function") {
                                callback();
                            }
                            break;
                        case 'console':
                            jApp.log(d);
                            break;
                        case 'popup':
                            $.each(d, function (t, h) {

                                $('#popup-success').text(h.label);
                            });

                            $('#popup-success').show();
                            window.setTimeout(function () {
                                $('#popup-success').hide();
                                $('#popup-success').text('');
                            }, 2000);
                            break;
                    }
                });

                if (typeof callback == "function") {
                    callback(json);
                }
            },
            error: function (xhr) {
                jApp.onAjaxError(xhr);
            }
        });
    },
    onAjaxError: function (xhr) {
        if (xhr.responseText.substring(0, 8) == "<script>") {

            $('#page-wrapper').prepend($(xhr.responseText));
        } else {

            if (xhr.status > 399) {

                console.log(xhr.status);
                $('#page-wrapper').prepend($('<div class="alert alert-danger">Chyba: '
                    + xhr.status + '</div>'));
                jApp.log(xhr.status);

            } else {

                alert('json data error');
                jApp.log(xhr.responseText);
            }
        }
    },
    log: function (str) {
        if (window.console) {
            console.log(str);
        }
    },
    goBack: function (param) {
        var url = document.referrer;
        if (param.length > 0) {
            if (url.indexOf('?') < 0) {
                url += '?' + param;
            } else {
                url += '&' + param;
            }
        }
        document.location = url;
    },
    submitForm: function (form, callback) {
        var params = {};
        $(form).find('[name]').each(function () {
            // checkbox input
            if ($(this).prop('type') == 'checkbox') {
                if (!$(this).prop('checked')) {
                    return;
                }
            }
            // radio input
            if ($(this).prop('type') == 'radio') {
                if (!$(this).prop('checked')) {
                    return;
                }
            }
            // array brackets in name
            if (this.name.search(/\[\]/) > 0) {
                if (!params[this.name]) {
                    params[this.name] = new Array;
                }
                params[this.name].push(this.value);
            } else {
                params[this.name] = this.value;
            }
        });

        jApp.jpost($(form).prop('action'), params, function (json) {
            if (typeof callback == 'function') {
                callback(json);
            }
        });
    },
    confirmDelete: function () {
        return confirm('Opravdu chcete smazat záznam?');
    },
    getJsonData: function (json, prop) {
        if (json.hasOwnProperty('data')) {
            if (json.data.hasOwnProperty(prop)) {
                return json.data[prop];
            }
        }
        return null;
    },
    showDatePicker: function (input, init_date) {
        if (!$(input).val()) {
            var value_source = $(input).data('value-source');
            if (value_source) {
                $(input).val($(value_source).val());
            }
        }
        if (typeof jQuery.fn.datepicker !== "undefined") {
            $(input).datepicker({
                numberOfMonths: 2,
                dateFormat: "d. m. yy",
                defaultDate: init_date,
                minDate: new Date(),
                //onSelect: function (date) {
                //    var set_to_date = $(this).data('set-to-date');
                //    if (set_to_date) {
                //        if (!$(set_to_date).val()) {
                //            $(set_to_date).val(date);
                //        }
                //    }
                //    var set_from_date = $(this).data('set-from-date');
                //    if (set_from_date) {
                //        if (!$(set_from_date).val()) {
                //            $(set_from_date).val(date);
                //        }
                //    }
                //}
            });
            //if (init_date) {
            //    $(input).datepicker('setDate', init_date);
            //}
            $(input).datepicker('show');
        }
        $(input).select();
    }
};

onloadfcs.push(function () {

    $('#contact-form').bind('submit', function (ev) {
        ev.preventDefault();

        $form = $(this);
        $form.find('input,textarea').each(function() {
            $(this).prop('disabled', true);
        });
        jApp.jpost('/?json=contact-send', {
            name:$form.find('input[name="name"]').val(),
            email:$form.find('input[name="email"]').val(),
            body:$form.find('textarea[name="body"]').val(),
            action:'send'
        }, function(json) {
            if (json.data.contact) {
                document.location=json.data.url;
            }
        });
        return false;
    });
});


$(document).bind('ready', function () {

    jApp.init();
});
